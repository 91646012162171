import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Input, ButtonIconVariant, ButtonIcon } from '@incodetech/ui';
import { isValidHttpUrl } from '../../../utils/utils';
import { useTranslation } from 'react-i18next';

const AuthConfigurationInput = ({
  name,
  label,
  errorMessage,
  required = false,
  disabled,
  isUrl = false,
}: {
  name: string;
  label: string;
  errorMessage: string;
  required?: boolean;
  disabled?: boolean;
  isUrl?: boolean;
}) => {
  const {
    control,
    watch,
    register,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();

  const fieldErrors: any[] = ((errors ?? {})[name] as any) ?? [];

  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const values = watch(name);

  return (
    <div className="input-wrapper">
      {fields.map((item, index) => {
        const fieldName = `${name}.${index}.value`;
        const value = watch(`${name}.${index}.value`);
        return (
          <div className="form-row" key={item.id}>
            <div className="form-column">
              <div className="redirect-uris">
                <Input
                  id={item.id}
                  defaultValue={value}
                  isDynamic
                  variant="full"
                  label={label}
                  {...register(fieldName, {
                    required: required ? errorMessage : false,
                    validate: value =>
                      isValidHttpUrl(value)
                        ? true
                        : `${t('clientRegistration.form.validUrlError')}`,
                  })}
                  disabled={disabled}
                  error={
                    fieldErrors[index] && fieldErrors[index].value?.message
                  }
                />
                {index > 0 ? (
                  <ButtonIcon
                    variant={ButtonIconVariant.DELETE}
                    onClick={() => remove(index)}
                    disabled={disabled}
                  />
                ) : null}
              </div>
            </div>
          </div>
        );
      })}
      {values[values?.length - 1]?.value ? (
        <ButtonIcon
          variant={ButtonIconVariant.ADD}
          onClick={() => append({ value: '' })}
          disabled={disabled}
        />
      ) : null}
    </div>
  );
};

export default AuthConfigurationInput;
