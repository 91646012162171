import React, { useState } from 'react';
import { Card, ButtonIcon, ButtonIconVariant, Button } from '@incodetech/ui';
import { useTranslation } from 'react-i18next';

import {
  useGetClients,
  useCreateClient,
  useUpdateClient,
  useDeleteClient,
  useRegenerateSecret,
  useGetClientRegistrations,
} from '../../hooks';
import { hasConfiguration } from '../../utils/authClientConfigUtils';
import {
  AuthClientSettingsKeys,
  ModalConfigData,
  ModalDeleteData,
  GeneratedDataModal as GeneratedDataModalType,
  ModalConfigFormData,
  AuthClientScopes,
  AuthClientGrantTypes,
  AuthClientMethods,
  AuthenticationTypes,
} from '../../types/authClientConfig';
import { ErrorCode, ORG_ID } from '../../constants/constants';
import AuthConfigurationModal from './components/AuthConfigurationModal';
import ConfirmDeleteModal from './components/ConfirmDeleteModal';
import GeneratedDataModal from './components/GeneratedDataModal';

import './Authentication.scss';

function Authentication() {
  const orgId = localStorage.getItem(ORG_ID);
  const { t } = useTranslation();
  const [modalData, setModalData] = useState<ModalConfigData>({
    isOpen: false,
    formData: {} as ModalConfigFormData,
    editing: false,
  });
  const [deleteModalState, setDeleteModalState] = useState<ModalDeleteData>({
    isOpen: false,
    clientId: '',
  });
  const [generatedDataModal, setGeneratedDataModal] =
    useState<GeneratedDataModalType>({
      isOpen: false,
      info: { clientId: '', clientSecret: '' },
    });
  const [serverError, setServerError] = useState('');
  const { data } = useGetClients();
  const { mutateAsync: addAuthConfig } = useCreateClient();
  const { mutateAsync: editAuthConfig } = useUpdateClient();
  const { mutateAsync: deleteConfig } = useDeleteClient();
  const { mutateAsync: regenerateSecret, isPending } = useRegenerateSecret();
  const { data: clientRegistrations } = useGetClientRegistrations();

  const submitAuthConfigModal = async (data: ModalConfigFormData) => {
    try {
      setServerError('');
      const { issuerUrl, authorizeUrl, tokenUrl, tokenSettings, ...restData } =
        data;
      const submitData = {
        ...restData,
        scopes: [...data.scopes, AuthClientScopes.OPEN_ID],
        authorizationGrantTypes: [
          ...data.authorizationGrantTypes,
          AuthClientGrantTypes.AUTHORIZATION_CODE,
        ],
        clientAuthenticationMethods: data.clientAuthenticationMethods
          .filter(d => d.checked)
          .map(m => m.value),
        redirectUris: data.redirectUris.map(item => item.value),
        postLogoutRedirectUris: data.postLogoutRedirectUris
          ?.map(item => item.value)
          ?.filter(Boolean),
        clientOrganization: orgId,
        registrationId:
          data.authenticationType === AuthenticationTypes.OKTA
            ? data.registrationId
            : '',
        tokenSettings: {
          'settings.token.reuse-refresh-tokens':
            tokenSettings['reuse-refresh-tokens'],
          'settings.token.access-token-time-to-live':
            tokenSettings['access-token-time-to-live'] * 60,
          'settings.token.refresh-token-time-to-live':
            tokenSettings['refresh-token-time-to-live'] * 60,
          'settings.token.authorization-code-time-to-live':
            tokenSettings['authorization-code-time-to-live'] * 60,
        },
      };

      const config = modalData.editing
        ? await editAuthConfig(submitData)
        : await addAuthConfig(submitData);

      if (!modalData.editing) {
        setGeneratedDataModal({
          isOpen: true,
          info: {
            clientId: config.clientId,
            clientSecret: config.clientSecret,
          },
        });
      }

      setModalData({
        isOpen: false,
        formData: {} as ModalConfigFormData,
        editing: false,
      });
    } catch (e) {
      let errorMessage = t('authConfiguration.error');

      if (e?.response?.data?.status === ErrorCode.NON_UNIQUE_CLIENT_NAME) {
        errorMessage = t(`authConfiguration.uniqueName`);
      }
      if ([5006, 5007, 5008].includes(e?.response?.data?.status ?? 0)) {
        errorMessage = t(`authConfiguration.${e.response.data.status}`);
      }

      setServerError(errorMessage);
      return;
    }
  };

  const handleDelete = async (clientId: string) => {
    await deleteConfig({ clientId });
    setDeleteModalState({ isOpen: false, clientId: '' });
  };

  const onRegerateSecret = async (watchedClientId: string) => {
    try {
      const { clientSecret } = await regenerateSecret({
        clientId: watchedClientId,
      });

      setGeneratedDataModal({
        isOpen: true,
        info: {
          clientId: '',
          clientSecret,
        },
      });
    } catch {}
  };

  return (
    <div className="authentication-page">
      <Card title={t('authConfiguration.title')}>
        <div className="table-wrapper">
          <table>
            <thead>
              <tr>
                <th className="list">{t('authConfiguration.name')}</th>
                <th className="clientId">{t('authConfiguration.clientId')}</th>
                <th className="list">
                  {t('authConfiguration.authenticationMethods')}
                </th>
                <th className="list">
                  {t('authConfiguration.authenticationType')}
                </th>
                <th className="list">{t('authConfiguration.actions')}</th>
              </tr>
            </thead>
            <tbody>
              {data?.registeredClients?.map(clientConfig => {
                const isReadOnly = clientConfig.readOnly;
                return (
                  <tr key={clientConfig.clientId}>
                    <td>
                      <span className="auth-cell-block">
                        {clientConfig.clientName ?? ''}
                      </span>
                    </td>
                    <td>
                      <span>{clientConfig.clientId}</span>
                    </td>
                    <td>
                      <ul>
                        {clientConfig.clientAuthenticationMethods?.map(
                          authenticationMethod => (
                            <li key={authenticationMethod}>
                              {authenticationMethod.replace(/_/g, ' ')}
                            </li>
                          ),
                        )}
                      </ul>
                    </td>
                    <td>
                      <span>{clientConfig.authenticationType ?? ''}</span>
                    </td>
                    <td>
                      <div className="action-cell">
                        <ButtonIcon
                          variant={
                            isReadOnly
                              ? ButtonIconVariant.VIEW
                              : ButtonIconVariant.EDIT
                          }
                          classes="auth-button-icon"
                          tooltip={t(
                            `authConfiguration.${
                              isReadOnly ? 'tooltipView' : 'tooltipEdit'
                            }`,
                          )}
                          onClick={() => {
                            setModalData({
                              isOpen: true,
                              // @ts-ignore
                              formData: {
                                ...clientConfig,
                                redirectUris: clientConfig.redirectUris.map(
                                  item => ({ value: item }),
                                ),
                                clientAuthenticationMethods: Object.values(
                                  AuthClientMethods,
                                )?.map(a => ({
                                  name: a,
                                  value: a,
                                  checked:
                                    clientConfig.clientAuthenticationMethods?.some(
                                      m => m === a,
                                    ),
                                })),
                                ...(clientConfig.postLogoutRedirectUris && {
                                  postLogoutRedirectUris:
                                    clientConfig.postLogoutRedirectUris.map(
                                      item => ({ value: item }),
                                    ),
                                }),
                              },
                              editing: true,
                            });
                          }}
                        />
                        {!isReadOnly && (
                          <ButtonIcon
                            classes="auth-button-icon"
                            variant={ButtonIconVariant.DELETE}
                            tooltip={t('authConfiguration.tooltipDelete')}
                            onClick={() =>
                              setDeleteModalState({
                                isOpen: true,
                                clientId: clientConfig.clientId,
                              })
                            }
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="card__footer">
          <Button
            label={t('authConfiguration.addNew')}
            variant="white"
            withIcon
            // @ts-ignore
            iconVariant="iconBtnAdd"
            onClick={() => {
              setModalData({
                isOpen: true,
                formData: {} as ModalConfigFormData,
                editing: false,
              });
            }}
          />
        </div>
      </Card>
      <AuthConfigurationModal
        isOpen={modalData.isOpen}
        onRequestClose={() => {
          setModalData({
            isOpen: false,
            formData: {} as ModalConfigFormData,
            editing: false,
          });
          setServerError('');
        }}
        serverError={serverError}
        formData={modalData.formData}
        editing={modalData.editing}
        handleFormSubmit={submitAuthConfigModal}
        onRegenerateSecret={onRegerateSecret}
        isRegeneratingSecret={isPending}
        clientRegistrations={clientRegistrations?.registrations}
      />
      <ConfirmDeleteModal
        isOpen={deleteModalState.isOpen}
        handleDelete={() => {
          handleDelete(deleteModalState.clientId);
        }}
        closeModal={() => setDeleteModalState({ isOpen: false, clientId: '' })}
      />
      <GeneratedDataModal
        isOpen={generatedDataModal.isOpen}
        info={generatedDataModal.info}
        closeModal={() =>
          setGeneratedDataModal({
            isOpen: false,
            info: { clientId: '', clientSecret: '' },
          })
        }
      />
    </div>
  );
}

export { Authentication };
export default Authentication;
