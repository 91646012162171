import React, { useState } from 'react';
import { Input, Select, Button, Text, Logo } from '@incodetech/ui';
import { useTranslation } from 'react-i18next';

import { ORG_ID, CLIENT_ID } from '../../constants/constants';
import { useOrgClients } from '../../hooks';

import './Login.scss';
import { Client } from '../../types/client';

function Login() {
  const { t } = useTranslation();
  const { mutateAsync, data: clients, isPending } = useOrgClients();
  const [email, setEmail] = useState('');
  const [client, setClient] = useState<string | null>(null);

  const goToLogin = (clientId: string) => {
    window.location.href = `/oauth2/authorization/${clientId}?client_id=${clientId}`;
  };

  const onGetLoginInfo = async () => {
    if (email) {
      try {
        const clients = await mutateAsync({ email });
        if (clients.length < 2) {
          const clientId = clients[0].clientId;
          localStorage.setItem(CLIENT_ID, clientId);
          localStorage.setItem(ORG_ID, clients[0].organization);
          goToLogin(clientId);
          return;
        }

        onClientChange(clients[0]);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const onClientChange = (client: Client) => {
    setClient(client.clientId);
    localStorage.setItem(ORG_ID, client.organization);
    localStorage.setItem(CLIENT_ID, client.clientId);
  };

  const login = () => {
    if (!client) {
      return;
    }

    goToLogin(client);
  };

  return (
    <div className="login-wrapper">
      <div className="incode-logo">
        <Logo />
      </div>
      <div className="loginBox">
        <h1 className="title">
          <Text tag="span">{t('login.title')}</Text>
        </h1>
        <h2 className="subtitle">
          <Text tag="span" variant="gray">
            {t('login.subtitle')}
          </Text>
        </h2>
        <Input
          id="email"
          name="email"
          label={t('login.email')}
          variant="full"
          value={email}
          onChange={e => setEmail(e.target.value)}
          onBlur={onGetLoginInfo}
          disabled={isPending}
        />
        {clients && clients.length > 1 ? (
          <>
            <Select
              id="client"
              name="client"
              label={t('login.organization')}
              options={clients.map(c => ({
                value: c.clientId,
                label: c.organization,
              }))}
              value={client ?? ''}
              onChange={e => {
                const client = clients.find(
                  c => c.clientId === e.currentTarget.value,
                );
                onClientChange(client);
              }}
            />
            <Button
              label={t('login.submit')}
              disabled={!client}
              variant="green"
              type="button"
              size="full"
              onClick={login}
            />
          </>
        ) : (
          <Button
            label={t('login.signIn')}
            disabled={!email}
            variant="green"
            type="button"
            size="full"
            onClick={onGetLoginInfo}
          />
        )}
      </div>
      <Logo isTrademark />
    </div>
  );
}

export { Login };
export default Login;
